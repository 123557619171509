
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    title: {
      type: String,
      default: '标题'
    }
  },
  setup() {
    return {}
  }
})
