
import { ref, reactive, watch, defineComponent } from 'vue'
import httpRequest from '@/service'
import { ElMessage } from 'element-plus/lib/components'
import type { ElForm } from 'element-plus'

export default defineComponent({
  emits: ['update:modelValue'],
  props: ['modelValue','sysName','sysCode'],
  setup(props: any, { emit }) {
    type FormInstance = InstanceType<typeof ElForm>
    const ruleFormRef = ref<FormInstance>()
    const dialogVisible = ref(props.modelValue)
    const ruleForm = reactive({
      unitName: '',
      userName: '',
      phone: ''
    })
    const rules = reactive({
      unitName: [
        {
          required: true,
          message: '请输入单位名称',
          trigger: 'blur'
        }
      ],
      userName: [
        {
          required: true,
          message: '请输入姓名',
          trigger: 'blur'
        }
      ],
      phone: [
        {
          required: true,
          message: '请输入联系电话',
          trigger: 'blur'
        }
      ]
    })
    // 提交申请
    const loading = ref(false)
    const feedBackPost = (form: any) => {
      loading.value = true
      httpRequest
        .post({
          url: 'experience',
          data: {
            unitName: form.unitName,
            userName: form.userName,
            phone: form.phone,
            sysName:props.sysName,
            sysCode:props.sysCode
          }
        })
        .then(() => {
          loading.value = false
          ElMessage({
            message: '体验申请已提交，专属客户经理将尽快与您联系，谢谢！',
            type: 'success'
          })
          closeDialog()
        })
        .catch((err) => {
          console.log(err)
          loading.value = false
        })
    }
    // 确定按钮
    const submitClick = async (formEl: FormInstance | undefined) => {
      if (!formEl) return
      await formEl.validate((valid) => {
        if (valid) {
          feedBackPost(ruleForm)
        }
      })
    }
    //关闭弹框清除数据
    const closeDialog = () => {
      dialogVisible.value = false
      ruleForm.unitName = ''
      ruleForm.userName = ''
      ruleForm.phone = ''
      emit('update:modelValue', dialogVisible.value)
    }
    watch(
      () => props.modelValue,
      (newValue) => {
        dialogVisible.value = newValue
      }
    )
    return {
      dialogVisible,
      ruleForm,
      rules,
      ruleFormRef,
      submitClick,
      closeDialog,
      loading
    }
  }
})
