import { toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-68c75f6c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "con-box" }
const _hoisted_2 = { class: "slot-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("h4", { class: "title-box" }, _toDisplayString(_ctx.title), 513 /* TEXT, NEED_PATCH */), [
      [_vShow, _ctx.title]
    ]),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ]))
}